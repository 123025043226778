<template>
  <b-row>
    <b-col sm="12" md="3" class="mb-1">
      <b-list-group>
        <b-list-group-item :active="$route.name.includes('companies-show')" :to="{name:'companies-show'}">Detail Info</b-list-group-item>
        <b-list-group-item :active="$route.name.includes('companies-numbering')" :to="{name:'companies-numbering'}">Format Penomoran</b-list-group-item>
        <b-list-group-item :active="$route.name.includes('companies-threshold')" :to="{name:'companies-threshold'}">Threshold Penyimpanan</b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col sm="12" md="9">
      <my-card>
        <template slot="body">
          <router-view />
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import {BListGroup,BListGroupItem} from 'bootstrap-vue'
export default {
  components:{
    BListGroup,
    BListGroupItem
  }
}
</script>

<style>

</style>